import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <section className="section">
      <div className="container has-text-centered">
        <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
          Seite wurde nicht gefunden!
        </h1>
        <br />
        <br />
        <Link className="btn" to="/">
          zurück zur Startseite
        </Link>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
